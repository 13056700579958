import { createUseStyles } from 'react-jss';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import ImageClick from '../components/images/ImageClick';
import ImageEmail from '../components/images/ImageEmail';
import ImagePhone from '../components/images/ImagePhone';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(theme => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contactEmailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactInput: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: '1rem 0.8rem',
    transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, margin 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&:focus': {
      borderColor: theme.colorPrimary,
      borderWidth: '2px',
      margin: '0.8rem',
      outline: '0',
    },
    width: 'auto',
  },
  contactInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  contactSendContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  header: {
    textAlign: 'center',
  },
  numberInput: {
    minHeight: '25px',
    width: '40px',
    marginRight: '1em',
  },
}));

const styleFeatureCard = {
  display: 'flex',
  flex: '1 1 320px',
  flexDirection: 'column',
  minWidth: '320px',
  width: '90%',
};

const validateCreditcardnumber = (inputNum) => {
  let digit;
  let flag;
  let sum;
  let _i;
  let _len;
  flag = true;
  sum = 0;
  const digits = (inputNum + '').split('').reverse();
  for (_i = 0, _len = digits.length; _i < _len; _i += 1) {
    digit = digits[_i];
    digit = parseInt(digit, 10);
    if ((flag = !flag)) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

const checkForm = (event) => {
  const goodCC = validateCreditcardnumber(event.target.ccNum.value);
  if (!goodCC) {
    event.preventDefault();
    alert('Invalid Credit Card Number');
    return false;
  }
  return true;
};

const SignUp = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Sign Up" />
      <h1 className={classes.header}>
        Sign Up
      </h1>
      <div className={classes.main}>
        <Card style={styleFeatureCard}>
          <form action="/api_public/sendSignupForm" method="post" onSubmit={checkForm}>
            <div className={classes.contactEmailContainer}>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Business Name
                </span>
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  className={classes.contactInput}
                  name="businessName"
                  placeholder="Business Name"
                  required
                  type="text"
                />
              </div>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Business Address
                </span>
                <input
                  className={classes.contactInput}
                  name="businessAddress"
                  placeholder="Business Address"
                  type="text"
                />
              </div>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Business Zipcode
                </span>
                <input
                  className={classes.contactInput}
                  name="businessZipcode"
                  placeholder="Business Zipcode"
                  type="text"
                />
              </div>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Business Country
                </span>
                <input
                  className={classes.contactInput}
                  name="businessCountry"
                  placeholder="Business Country"
                  required
                  type="text"
                  defaultValue="USA"
                />
              </div>
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Contact Name
                </span>
                <input
                  className={classes.contactInput}
                  name="name"
                  placeholder="Contact Name"
                  required
                  type="text"
                />
              </div>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Contact Email
                </span>
                <input
                  className={classes.contactInput}
                  name="email"
                  placeholder="Contact Email"
                  type="email"
                />
              </div>
              <div className={classes.contactInputContainer}>
                <span style={{ fontSize: 'small' }}>
                  Contact Phone
                </span>
                <input
                  className={classes.contactInput}
                  name="tel"
                  placeholder="Contact Phone"
                  type="tel"
                />
              </div>
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <br />
              <label htmlFor="staffNum">
                <input type="number" className={classes.numberInput} id="staffNum" name="staffNum" placeholder="0" required />
                Number of Staff
              </label>
              <br />
              <span style={{ fontSize: 'small' }}>
                KounterTop includes up to 25 staff. Each additional 25 staff is $50 per month
              </span>
              <br />
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <h3>
                Modules
              </h3>
              <span style={{ fontSize: 'small' }}>
                Management tools are free. Get one KounterTop module at the show special of $75 per month for the first 12 months. Each additional module is $50 per month
              </span>
              <br />
              <label htmlFor="moduleFoodPrep">
                <input type="checkbox" id="moduleFoodPrep" name="moduleFoodPrep" value="moduleFoodPrep" defaultChecked />
                Food Prep Management Module
              </label>
              <br />
              <label htmlFor="moduleCheckout">
                <input type="checkbox" id="moduleCheckout" name="moduleCheckout" value="moduleCheckout" defaultChecked />
                Tips, Cash and Drawers Module
              </label>
              <br />
              <label htmlFor="modulePresence">
                <input type="checkbox" id="modulePresence" name="modulePresence" value="modulePresence" defaultChecked />
                Scheduling and Presence Module
              </label>
              <br />
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <h3>
                Hardware Purchasing
              </h3>
              <label htmlFor="screenBuy15">
                <input type="number" className={classes.numberInput} id="screenBuy15" name="screenBuy15" defaultValue="0" />
                Touch Screen 15in $349 ea
              </label>
              <br />
              <label htmlFor="screenBuy24">
                <input type="number" className={classes.numberInput} id="screenBuy24" name="screenBuy24" defaultValue="0" />
                Touch Screen 24in $595 ea
              </label>
              <br />
              <label htmlFor="screenBuy27">
                <input type="number" className={classes.numberInput} id="screenBuy27" name="screenBuy27" defaultValue="0" />
                Touch Screen 27in $895 ea
              </label>
              <br />
              <span style={{ fontSize: 'small' }}>
                Shipping and tax not included in prices
              </span>
              <br />
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <h3>
                Hardware Rental
              </h3>
              <label htmlFor="screenRent15">
                <input type="number" className={classes.numberInput} id="screenRent15" name="screenRent15" defaultValue="0" />
                Touch Screen 15in $20 per mo
              </label>
              <br />
              <label htmlFor="screenRent24">
                <input type="number" className={classes.numberInput} id="screenRent24" name="screenRent24" defaultValue="0" />
                Touch Screen 24in $30 per mo
              </label>
              <br />
              <label htmlFor="screenRent27">
                <input type="number" className={classes.numberInput} id="screenRent27" name="screenRent27" defaultValue="0" />
                Touch Screen 27in $50 per mo
              </label>
              <br />
              <span style={{ fontSize: 'small' }}>
                Shipping and tax not included in prices
              </span>
              <br />
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <br />
              <textarea
                className={classes.contactInput}
                name="notes"
                placeholder="Notes"
              />
              <br />
              <hr style={{ marginLeft: '0', marginRight: '0' }} />
              <br />
              <input
                className={classes.contactInput}
                name="ccName"
                placeholder="Name on Credit Card"
                type="text"
              />
              <br />
              <input
                className={classes.contactInput}
                name="ccNum"
                placeholder="Credit Card Number"
                type="number"
              />
              <br />
              <input
                className={classes.contactInput}
                name="ccExp"
                placeholder="Credit Card Expiration"
                type="text"
              />
              <br />
              <input
                className={classes.contactInput}
                name="ccCvv"
                placeholder="Credit Card CVV"
                type="number"
              />
            </div>
            <br />
            <hr style={{ marginLeft: '0', marginRight: '0' }} />
            <br />
            If a module is added, $199 set up fee will be charged today. In 30 days you will be charged your first month&apos;s fee.
            <br />
            <br />
            Hardware orders will be confirmed in a follow up phone call and will not be automatically charged.
            <br />
            <br />
            <label htmlFor="terms">
              <input type="checkbox" id="terms" name="terms" value="Yes" />
              I have read and accept the
              {' '}
              <a href="/terms" target="_blank">
                Terms &amp; Conditions
              </a>
            </label>
            <br />
            <br />
            <hr style={{ marginLeft: '0', marginRight: '0' }} />
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <label htmlFor="creditMarianne">
                <input type="radio" id="creditMarianne" name="credit" value="Marianne" />
                Marianne
              </label>
              <label htmlFor="creditAaron">
                <input type="radio" id="creditAaron" name="credit" value="Aaron" />
                Aaron
              </label>
              <label htmlFor="creditDude">
                <input type="radio" id="creditDude" name="credit" value="Dude" />
                Drew
              </label>
              <label htmlFor="creditDudette">
                <input type="radio" id="creditDudette" name="credit" value="Dudette" />
                Kristen
              </label>
            </div>
            <div
              className={classes.contactSendContainer}
            >
              <Button type="submit">
                SAVE
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Layout>
  );
};

export default SignUp;
