import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = createUseStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    color: theme.colorPrimary,
    border: `1px solid ${theme.colorPrimaryHalfOpacity}`,
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    outline: 'none',
    padding: '8px 24px',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: theme.colorPrimaryLowestOpacity,
      border: `1px solid ${theme.colorPrimary}`,
    },
  },
}));

const Button = ({ children, ...props }) => {
  const classes = useStyles(props);
  return (
    <button className={classes.root} type="button" {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
