import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = createUseStyles(() => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);',
    margin: '1rem',
    padding: '1rem',
  },
}));

const Card = ({ children, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
